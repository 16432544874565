import Typography from 'components/Typography';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import styles from '../RequestDetails.module.scss';
import {get} from 'lodash';
import Button from 'components/Button';

type Props = {
  drugs: any[];
  card_number: string;
  viewOrder: (id: number) => void;
};

function Otcs({drugs, card_number, viewOrder}: Props) {
  if (drugs.length === 0) return null;

  return (
    <>
      {drugs.map((drug) => (
        <Card key={drug.id}>
          <CardContent px="sm" pt="lg" pb="sm">
            {/* <Typography style={{marginBottom: 15}} component="h3" variant="h3">
          Orders
        </Typography> */}
            <div className={styles.otc}>
              <div className={styles.row}>
                <Typography variant="p" className={styles.drug__name}>
                  Rx #
                </Typography>
                <Typography className={styles.payment__value} variant="strong">
                  {get(drug, 'rx_no', '-') || '-'}
                </Typography>
              </div>
              <div className={styles.row}>
                <Typography variant="p" className={styles.drug__name}>
                  Order #
                </Typography>
                <Typography className={styles.payment__value} variant="strong">
                  M{get(drug, 'id', '-') || '-'}
                </Typography>
              </div>
              <div className={styles.row}>
                <Typography variant="p" className={styles.drug__name}>
                  Transaction
                </Typography>
                <Typography className={styles.payment__value} variant="strong">
                  {get(drug, 'sync_time', '-') || '-'}
                </Typography>
              </div>
              <div className={styles.row}>
                <Typography variant="p" className={styles.drug__name}>
                  Card #
                </Typography>
                <Typography className={styles.payment__value} variant="strong">
                  {card_number || '****-****'}
                </Typography>
              </div>
              <div style={{marginTop: 20}} className={styles.row}>
                <Typography variant="p" className={styles.drug__name}>
                  Amount
                </Typography>
                <Typography className={styles.payment__value} variant="strong">
                  ${Number(get(drug, 'last_paid', 0)).toFixed(2) || '0.00'}
                </Typography>
              </div>
              <Button
                color="blue"
                onClick={() => viewOrder(get(drug, 'id', 0))}
                className={styles.notify_action}
              >
                View order
              </Button>
            </div>
          </CardContent>
        </Card>
      ))}
    </>
  );
}

export default Otcs;
